<template>
  <div class="main-page">
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col comp-grid">
            <div class="">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading">
                    <div class="row q-col-gutter-x-md">
                      <div class="col-12">
                        <div class="grid">
                          <div class="md:col-6">
                            <span class="mr-1">{{ $t("amount") }} (GHS): </span>
                          </div>
                          <div class="md:col-6">
                            {{ item.amount }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid">
                          <div class="md:col-6">
                            <span class="mr-1"
                              >{{ $t("transaction_id") }}:</span
                            >
                          </div>
                          <div class="md:col-6">
                            {{ item.transaction_id }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid">
                          <div class="md:col-6">
                            <span class="mr-1"> {{ $t("telephone") }}: </span>
                          </div>
                          <div class="md:col-6">
                            {{ item.msisdn }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid">
                          <div class="md:col-6">
                            <span class="mr-1">
                              {{ $t("payment_method") }}:
                            </span>
                          </div>
                          <div class="md:col-6">
                            {{ item.payment_method }}
                          </div>
                        </div>
                        <hr />
                        <div class="grid">
                          <div class="md:col-6">
                            <span class="mr-1">{{ $t("status") }}: </span>
                          </div>
                          <div v-if="item" class="md:col-6 text-green-500">
                            {{ $t("Success") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="loading">
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "viewPaymentsPage",
  components: {},
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "payments",
    },
    routeName: {
      type: String,
      default: "paymentsview",
    },
    pagePath: {
      type: String,
      default: "payments/view",
    },
    apiPath: {
      type: String,
      default: "payments/view",
    },
  },
  data() {
    return {
      item: {
        default: {},
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Payments Details";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["payments/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("payments/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("payments", ["fetchRecord", "deleteRecord"]),
    getActionMenuModel(data) {
      return [];
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
